body, html {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .main {
    color: white;
    font-family: "Courier New", Courier, monospace;
    font-size: 25px;
  }
  
  .bottomleft {
    position: absolute;
    bottom: 0;
    left: 16px;
  }

  .bottomright {
      position: fixed;
      bottom: 0;
      right: 0;
}
  
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  hr {
    margin: auto;
    width: 40%;
  }

a:link {
  color: white;
}

a:visited {
  color: white;
}

a:hover {
  color: white;
}

a:active {
  color: white;
}